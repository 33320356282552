@use "../../base";

.horizontalLayoutContainer {
  display: flex;

  &.normal {
    @include base.horizontal-space-margin(0.75rem);
  }

  &.compact {
    @include base.horizontal-space-margin(0.5rem);
  }

  &.center {
    justify-content: center;
  }

  &.end {
    justify-content: flex-end;
  }

  &.spaceBetween {
    justify-content: space-between;
  }

  &.alignCenter {
    align-items: center;
  }
}
