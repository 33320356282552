@use "./base";

.toast {
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.4),
    0px 6px 10px 0px rgba(0, 0, 0, 0.28), 0px 1px 18px 0px rgba(0, 0, 0, 0.24);
}

.toastBody {
  @include base.text-viewer;
}

:global {
  .ui.message p {
    opacity: 1;
  }

  .ui.popup {
    z-index: 500;
  }

  @media only screen and (min-width: 1200px) {
    .ui.container {
      width: 90%;
    }
  }

  @media only screen and (max-width: 767px) {
    .ui.icon.message {
      flex-direction: column;
    }

    .ui.message {
      > .icon {
        margin-right: 0;
      }

      > .icon:first-child {
        margin-bottom: 0.6rem;
      }
    }
  }

  .ui.divider.inverted {
    border-bottom-color: rgba(255, 255, 255, 0.7) !important;
  }

  .ui.vertical.menu .item > i.icon {
    float: none;
    margin: 0 0.5rem 0 0;
  }

  .text-viewer {
    @include base.text-viewer;
  }

  .center-text {
    text-align: center;
  }

  .flex-display {
    display: flex !important;
  }

  .pointer {
    cursor: pointer;
  }

  .full-width {
    width: 100%;
  }

  .hover-dimming {
    :hover {
      background-color: rgb(240, 240, 240);
    }
  }
}
