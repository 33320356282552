$primary-color: #2c4a66;
$green-color: #21ba45;
$orange-color: #f2711c;
$red-color: #db2828;
$teal-color: #00b5ad;
$grey-color: #767676;
$light-grey-color: #c2c2c2;
$blue-color: #2185d0;
$pink-color: #e03997;

@mixin primary-color-link {
  a {
    color: white;
    text-decoration: underline;
    font-weight: bold;
    opacity: 0.85;

    &:hover {
      opacity: 1;
    }
  }
}

@mixin horizontal-space-margin($spacing) {
  > * {
    margin-right: $spacing !important;
    margin-left: 0 !important;

    &:last-child {
      margin-right: 0 !important;
    }
  }
}

@mixin vertical-space-margin($spacing) {
  > * {
    margin-bottom: $spacing !important;
    margin-top: 0 !important;

    &:last-child {
      margin-bottom: 0 !important;
    }
  }
}

@mixin table {
  min-height: 35rem;
  height: 75vh;
}

@mixin truncate-text {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

@mixin text-viewer {
  white-space: pre-wrap;
  word-break: break-word;
}
